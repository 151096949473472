import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  DataTableSkeleton,
  Pagination,
} from "carbon-components-react";
import { Download32 } from "@carbon/icons-react";
import { BlobProvider } from "@react-pdf/renderer";
import ApplicantPdf from "../util/ApplicantPdf";
import { useContext } from "react";
import { HeedContext } from "../gatsby-theme-carbon/components/Layout";
import {
  fetchAuthenticatedContent,
  UserContext,
} from "@parallelpublicworks/entitysync";
import getLorsLink from "../util/getLorsLink";
import parseAnswerAttached from "../util/parseAnswerAttached";
import { navigate } from "gatsby";

const REACT_APP_ENTITYSYNC_BASE_URL = process.env.REACT_APP_ENTITYSYNC_BASE_URL;

const headers = [
  {
    key: "name",
    header: "Name",
  },
  {
    key: "submission_date",
    header: "Submission date",
  },
  {
    key: "status",
    header: "Status",
  },
];

function getRedableDate(date) {
  const d = new Date(date);
  return d.toLocaleDateString();
}

function ApplicationListTable({ allNodeTask, location }) {
  const userContext = useContext(UserContext);
  const userState =
    userContext && typeof userContext[0] !== "undefined"
      ? userContext[0]
      : null;

  const { user, answersList, _setAnswers, userData } = useContext(HeedContext);

  const [loading, setLoading] = useState(false);
  const [paginationOptions, setPaginationOptions] = React.useState({
    currentPage: 1,
    pageSize: 50,
  });
  const [applicantAnswers, setApplicantAnswers] = useState([]);

  useEffect(() => {
    const page = new URLSearchParams(location.search).get("page");
    const limit = new URLSearchParams(location.search).get("limit") || 50;
    if (!page) return navigate("/application-list?page=1&limit=50");
    else
      setPaginationOptions({
        ...paginationOptions,
        currentPage: +page,
        pageSize: +limit,
      });
  }, []);

  useEffect(() => {
    if (user.id) {
      fetchApplicants();
    }
  }, [user.id, paginationOptions.pageSize]);

  async function fetchApplicants(args = {}) {
    let { urlPagination, currentPage, pageSize } = args;

    if (loading) return;

    const page = new URLSearchParams(location.search).get("page");
    const _pageSize = new URLSearchParams(location.search).get("limit");

    if (!currentPage && page) {
      currentPage = +page;
    } else if (!currentPage) {
      currentPage = paginationOptions.currentPage;
    }
    if (!pageSize && _pageSize) {
      pageSize = +_pageSize;
    } else if (!pageSize) {
      pageSize = paginationOptions.pageSize;
    }

    let endpoint = "";
    if (!urlPagination) {
      endpoint = "node/answers/";
      endpoint = `node/answers/?filter[uid.id][value]=${user.id}`;
      endpoint += `&include=field_biosketch,field_res_personal,field_lor_chair`;

      endpoint += `&page[offset]=${(+currentPage - 1) * pageSize}`;
      endpoint += `&page[limit]=${pageSize}`;

      endpoint += `&sort=field_first_name,field_last_name`;
    } else {
      endpoint = urlPagination.href.replace(
        `${REACT_APP_ENTITYSYNC_BASE_URL}/jsonapi/`,
        ""
      );
    }

    setLoading(true);

    const resp = await fetchAuthenticatedContent(
      userState.auth,
      userContext[1],
      endpoint,
      "GET"
    );

    setLoading(false);

    if (resp && resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
      setPaginationOptions({
        ...paginationOptions,
        currentPage: currentPage ? currentPage : 1,
        count: resp.meta.count,
        links: resp.links,
        pageSize: pageSize,
      });
      let applicants = parseAnswerAttached(resp);
      setApplicantAnswers(applicants);
    }
  }

  const onPageChange = async (e) => {
    let urlPagination = paginationOptions.links.self;

    if (e.page > paginationOptions.currentPage)
      urlPagination = paginationOptions.links.next;
    else urlPagination = paginationOptions.links.prev;

    navigate(`/reviewer?page=${e.page}&limit=${e.pageSize}`);
    fetchApplicants({ pageSize: e.pageSize, currentPage: e.page });
  };

  const rows = applicantAnswers.map((item) => {
    return {
      id: item.id,
      name:
        item.attributes.field_first_name +
        " " +
        item.attributes.field_last_name,
      submission_date:
        item.attributes.changed && item.attributes.field_submitted == true
          ? getRedableDate(item.attributes.changed)
          : "",
      status: item.attributes.field_submitted ? "Submitted" : "In progress",
      field_biosketch: item.attributes.field_biosketch?.attributes?.uri?.value,
      field_letters_of_recommendation:
        item.attributes.field_letters_of_recommendation,
    };
  });

  if (loading) return <DataTableSkeleton />;

  return (
    <>
      <DataTable rows={rows} headers={headers}>
        {({
          rows,
          headers,
          getTableProps,
          getHeaderProps,
          getRowProps,
          onInputChange,
        }) => (
          <TableContainer>
            <TableToolbar>
              <TableToolbarContent>
                <TableToolbarSearch
                  placeholder="Filter applicants"
                  onChange={onInputChange}
                />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    if (
                      header.key === "field_biosketch" ||
                      header.key === "field_letters_of_recommendation"
                    )
                      return null;

                    return (
                      <TableHeader
                        {...getHeaderProps({ header, isSortable: false })}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      if (
                        cell.info.header === "field_biosketch" ||
                        cell.info.header === "field_letters_of_recommendation"
                      )
                        return null;

                      return (
                        <TableCell
                          key={cell.id}
                          onClick={() => {
                            _setAnswers(
                              applicantAnswers.find(
                                (item) => item.id === row.id
                              )
                            );
                            navigate("/application");
                          }}
                        >
                          {cell.value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={onPageChange}
        page={paginationOptions.currentPage}
        pageSize={paginationOptions.pageSize}
        pageSizes={[25, 50]}
        size="md"
        totalItems={paginationOptions.count}
      />
    </>
  );
}

ApplicationListTable.propTypes = {
  allNodeTask: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ApplicationListTable;
