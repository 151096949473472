export default (answers) => {
    let the_source;
    if(answers.id && typeof answers.id === 'string' && answers.id.length > 0){
        the_source = answers;
        if(the_source.local_id){
            delete the_source.local_id
        }
    }else if(answers.local_id && typeof answers.local_id === 'string' && answers.local_id.length > 0){
        the_source = {local_id: answers.local_id } 
    }
    // console.warn('SETTING SOURCE', the_source);
    return the_source;
}
