import React, { useContext, useEffect } from "react";
import { Button } from "carbon-components-react";
import { ArrowLeft16 } from "@carbon/icons-react";
import { navigate } from "gatsby";
import ProgressBar from "../components/ProgressBar";
import Deadline from "../components/Deadline";
import { HeedContext } from "../gatsby-theme-carbon/components/Layout";
import { InlineLoading } from "carbon-components-react";
import ApplicationListTable from "../components/ApplicationListTable";

function Application({ location }) {
  const heed = useContext(HeedContext);

  useEffect(() => {
    heed.fetchUserAnswers();
  }, []);

  if (heed?.user?.id && !heed?.user?.is_eligible) {
    navigate("/");
    return <></>;
  }
  if (heed.loadingAnswers) {
    return <InlineLoading description="Loading..." status="active" />;
  }
  const applicationLayout = (
    <div
      className={`application-wrapper application ${
        heed?.answers?.all_done ? "complete" : ""
      }`}
    >
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            <Button kind="tertiary" onClick={(e) => navigate("/")}>
              <ArrowLeft16 /> <span>Back to profile</span>
            </Button>
            <div style={{padding: '.5rem'}}>
              <Deadline />
            </div>
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
          <div>
            <div
              id="application-title"
              className="padd"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2>Your submissions</h2>
              <Button
                onClick={() => {
                  navigate("/application");
                  heed._setAnswers(null, true);
                }}
              >
                New Submission
              </Button>
            </div>
            {/* <TasksNav /> */}
            <ApplicationListTable location={location} />
          </div>
        </div>
      </div>
    </div>
  );
  return applicationLayout;
}

export default function ApplicationPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: {
      title: "HEED Applications",
      tabs: ["Profile", "Application"],
    },
  };
  return <Application pageContext={pageContext} location={location} />;
}
